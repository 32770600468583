import React from 'react';

import { UseMutationResult } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { Control, FieldErrorsImpl } from 'react-hook-form';

import { TextInput } from 'components/common/Input/TextInput';
import { Typography } from 'components/common/Typography/Typography';
import { extractValidationErrors } from 'lib/general/extractors';
import { UserServiceNS } from 'services/user/types';
import { InputFieldType, ServiceErrorResponse } from 'types/common';

interface BasicInformationProps {
  control: Control<{
    phone: string;
    firstName: string;
    lastName: string;
    password: string;
  }>;
  errors: Partial<FieldErrorsImpl>;
  onboardMutation:
    | UseMutationResult<
        AxiosResponse<unknown>,
        ServiceErrorResponse<string | number | symbol>,
        UserServiceNS.OnboardAdminBody,
        unknown
      >
    | UseMutationResult<
        AxiosResponse<unknown>,
        ServiceErrorResponse<string | number | symbol>,
        UserServiceNS.OnboardStaffBody,
        unknown
      >;
}

export function BasicInformation({
  control,
  errors,
  onboardMutation,
}: BasicInformationProps) {
  // const [code, setCode] = useState<CountryCode>();

  return (
    <div className="w-full">
      <div className="flex justify-between">
        <Typography variant="h5">Basic Information</Typography>
      </div>
      <div className="grid grid-cols-6">
        <div className="col-span-6 flex flex-col">
          <div className=" mt-4 flex justify-between">
            <TextInput
              name="firstName"
              type={InputFieldType.Text}
              control={control}
              helperText={
                extractValidationErrors('firstName', onboardMutation, errors)
                  .msg
              }
              error={
                extractValidationErrors('firstName', onboardMutation, errors)
                  .hasError
              }
              placeholder="First Name"
              className=" "
              fullWidth
            />
            <span className="mx-2" />
            <TextInput
              name="lastName"
              type={InputFieldType.Text}
              control={control}
              helperText={
                extractValidationErrors('lastName', onboardMutation, errors).msg
              }
              error={
                extractValidationErrors('lastName', onboardMutation, errors)
                  .hasError
              }
              placeholder="Last Name"
              className=" "
              fullWidth
            />
          </div>
          <div className=" mt-0 flex justify-between">
            <TextInput
              removeWhiteSpace
              type={InputFieldType.Password}
              name="password"
              control={control}
              helperText={
                extractValidationErrors('password', onboardMutation, errors).msg
              }
              error={
                extractValidationErrors('password', onboardMutation, errors)
                  .hasError
              }
              placeholder="Password"
              fullWidth
            />
            <span className="mx-2" />
            <TextInput
              removeWhiteSpace
              type={InputFieldType.Password}
              name="confirmPassword"
              control={control}
              helperText={
                extractValidationErrors(
                  'confirmPassword',
                  onboardMutation,
                  errors
                ).msg
              }
              error={
                extractValidationErrors(
                  'confirmPassword',
                  onboardMutation,
                  errors
                ).hasError
              }
              placeholder="Confirm Password"
              fullWidth
            />
          </div>
          <div className="flex items-center space-x-3">
            <TextInput
              type={InputFieldType.Phone}
              placeholder="Phone Number"
              className="placeholder:text-zinc-400 "
              fullWidth
              name="phone"
              control={control}
              helperText={
                extractValidationErrors('phone', onboardMutation, errors).msg
              }
              error={
                extractValidationErrors('phone', onboardMutation, errors)
                  .hasError
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}
