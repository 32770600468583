import { ArrowLongRightIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';

// eslint-disable-next-line import/extensions
import { Button } from 'components/common/Button/Button';
import { TextInput } from 'components/common/Input/TextInput';
import { Typography } from 'components/common/Typography/Typography';
import { loginCredSchema } from 'constants/validation-schema';
import { useFormWithErrors } from 'hooks/useFormWithErrors';
import { useMutationWithToast } from 'hooks/useMutationWithToast';
import { AuthenticationService } from 'services/authentication';
import { InputFieldType } from 'types/common';

type LoginFormType = {
  email: string;
  password: string;
};

export function Login() {
  const activeRole = localStorage.getItem('active_role');
  const navigate = useNavigate();
  const loginMutation = useMutationWithToast(AuthenticationService.login, {});
  const { handleSubmit, control } = useFormWithErrors<LoginFormType>({
    mutation: loginMutation,
    schema: loginCredSchema,
  });

  const onSubmit = handleSubmit((formData) => {
    if (activeRole) {
      localStorage.clear();
    }
    loginMutation.mutate(
      { email: formData.email, password: formData.password },
      {
        onSuccess: ({ data: { url, loginToken, role } }) => {
          if (role?.name === 'Patient') {
            navigate({ pathname: '/patient-login' });

            return;
          }
          const _url = url.replace('redirect', 'app');
          localStorage.setItem('login_redirect', _url);
          localStorage.setItem('active_role', role.name);
          window.location.replace(`${url}?login_token=${loginToken}`);
        },
      }
    );
  });

  return (
    <form
      onSubmit={onSubmit}
      className="grid place-items-center rounded-sm px-8 py-10 pb-8">
      <h1 className="mb-8 text-white">LOGIN</h1>

      <TextInput
        removeWhiteSpace
        type={InputFieldType.Text}
        name="email"
        control={control}
        placeholder="Email"
        fullWidth
      />

      <TextInput
        removeWhiteSpace
        type={InputFieldType.Password}
        name="password"
        control={control}
        placeholder="Password"
        fullWidth
      />
      <div className="row mt-2 flex w-full items-center justify-between">
        <Typography
          onClick={() => {
            navigate('../forgot-password');
          }}
          variant="subtitle3"
          className="cursor-pointer !text-md font-semibold text-white underline">
          Forgot password?
        </Typography>
        <Button
          className="!text-md "
          type="submit"
          loading={loginMutation.isLoading}
          disabled={loginMutation.isLoading}>
          Login
          <ArrowLongRightIcon className="ml-8 h-5 w-5 text-white" />
        </Button>
      </div>
    </form>
  );
}
