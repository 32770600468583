import React, {
  LegacyRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { CameraIcon, PencilIcon } from '@heroicons/react/24/outline';
import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import { format, isFuture } from 'date-fns';
import { Controller, useWatch } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Button } from 'components/common/Button/Button';
import { CheckBox } from 'components/common/CheckBox/CheckBox';
import { DayPickerWithInput } from 'components/common/DatePicker/DatePicker';
import { DropDown } from 'components/common/DropDown/DropDown';
import { TextInput } from 'components/common/Input/TextInput';
import { LoadingSpinner } from 'components/common/Loading/LoadingSpinner';
import { Modal } from 'components/common/Modal/Modal';
import { RadioButton } from 'components/common/RadioButton/RadioButton';
import { Typography } from 'components/common/Typography/Typography';
import { TypoWithLink } from 'components/common/TypoWithLink/TypoWithLink';
import { Agreements } from 'components/patient-onboard/Agreements';
import { BHRTAgreement } from 'components/patient-onboard/BHRTAgreement';
import { ServiceAgreement } from 'components/patient-onboard/ServiceAgreement';
import { QueryKeys } from 'constants/query-keys';
import { patientOnboardSchema } from 'constants/validation-schema';
import { useFormWithErrors } from 'hooks/useFormWithErrors';
import { useMutationWithToast } from 'hooks/useMutationWithToast';
import { extractValidationErrors } from 'lib/general/extractors';
import { UserService } from 'services/user';
import { ThemeName } from 'theme/types';
import { InputFieldType } from 'types/common';
import { formatSsn } from 'utils/common';

import ProfileIcon from '../../../assets/images/user-Light.svg';

import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';

type OnboardPatientFormType = {
  firstName: string;
  lastName: string;
  password: string;
  phone: string;
  gender: string;
  married: string;
  dob: string;
  showDriverLicense: string;
  emergencyContactNumber: string;
  emergencyContactName: string;
  expDate: string;
  stateId: string;
  licenseNumber?: string;
  ssn?: string;
};

type AgreementType = 'Emailing' | 'Studies' | 'Offerings' | 'WhyLicense';
export function PatientOnboard() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [imageError, setImageError] = useState(false);
  const [showAgreement, setShowAgreement] = useState(false);
  const [agreementType, setAgreementType] = useState<AgreementType>();
  const token = searchParams.get('token') ?? '';
  const [preview, setPreview] = useState<string>();
  const [showServiceAgreement, setShowServiceAgreement] = useState(false);
  const [showBHRTAgreement, setShowBHRTAgreement] = useState({
    state: false,
    url: '',
  });

  const [emailing, setEmailing] = useState(false);
  const [studies, setStudies] = useState(false);
  const [offering, setOffering] = useState(false);
  const [smsConsent, setSmsConsent] = useState(false);
  const [licensePreview, setLicensePreview] = useState<string>();
  const [dobDatePicker, setDobDatePicker] = useState(false);
  const [licenseExpDatePicker, setLicenseExpDatePicker] = useState(false);

  const [licenseError, setLicenseError] = useState(false);

  const hiddenFileInput = useRef<HTMLInputElement>();
  const hiddenFileLicenseInput = useRef<HTMLInputElement>();
  const [isPictureMenuHidden, setIsPictureMenuHidden] = useState<boolean>(true);

  const addLicensePictureMutation = useMutationWithToast(
    UserService.uploadPatientLicenseImage
  );

  const addPhotoIdentityMutation = useMutationWithToast(
    UserService.uploadIdentityImage
  );

  const patientOnboardMutation = useMutationWithToast(
    UserService.onboardPatient,
    {}
  );

  const addProfilePictureMutation = useMutationWithToast(
    UserService.uploadPatientProfileImage
  );

  const getStatesQuery = useQuery([QueryKeys.States], () =>
    UserService.getStates()
  );
  const { handleSubmit, control, errors, setError, clearErrors } =
    useFormWithErrors<OnboardPatientFormType>({
      mutation: patientOnboardMutation,
      schema: patientOnboardSchema,
    });

  const showDriverLicense = useWatch({
    control,
    name: 'showDriverLicense',
    defaultValue: 'Yes',
  });

  const isMarried = useWatch({
    control,
    name: 'married',
    defaultValue: 'Yes',
  });

  const closePictureMenu = useCallback((event: MouseEvent) => {
    if ((event?.target as SVGElement).id === 'pencilIcon') return;
    setIsPictureMenuHidden(true);
  }, []);

  useEffect(() => {
    document.body.addEventListener('click', (e) => closePictureMenu(e));

    return () => document.body.removeEventListener('click', closePictureMenu);
  }, [closePictureMenu]);

  const handleClick = (type: string) => {
    if (type === 'profile') {
      hiddenFileInput?.current?.click();
    } else if (type === 'license') {
      hiddenFileLicenseInput?.current?.click();
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target?.files?.[0];
    const { id } = event.target;
    if (!file || !id) {
      return;
    }
    // eslint-disable-next-line
    event.target.value = '';
    if (id === 'profile') {
      addProfilePicture(file);
    } else if (id === 'license') {
      addLicensePicture(file);
    }
  };
  const addProfilePicture = (file: File) => {
    if (file) {
      setImageError(false);
      addProfilePictureMutation.mutate(
        { picture: file, token: token || '' },
        {
          onSuccess: () => {
            setPreview(URL.createObjectURL(file));
          },
        }
      );
    }
  };

  const addLicensePicture = (file: File) => {
    setLicensePreview(URL.createObjectURL(file));
    if (!!file && file?.size > 30000000) {
      toast.error('Picture size should be less than 30MB');

      return;
    }

    if (file) {
      if (showDriverLicense === 'Yes') {
        addLicensePictureMutation.mutate(
          { picture: file, token: token || '' },
          {
            onSuccess: () => {
              setLicensePreview(URL.createObjectURL(file));
            },
          }
        );
      } else {
        addPhotoIdentityMutation.mutate(
          { picture: file, token: token || '' },
          {
            onSuccess: () => {
              setLicensePreview(URL.createObjectURL(file));
            },
          }
        );
      }
    }
  };

  function focusOnDateInput(name: string) {
    (
      document.querySelector(`div[id="${name}"]`) as HTMLInputElement | null
    )?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }

  const onNext = handleSubmit((d) => {
    if (!preview) {
      setImageError(true);

      return;
    }
    if (!licensePreview) {
      setLicenseError(true);

      return;
    }

    if (!isFuture(new Date(d.expDate)) && showDriverLicense === 'Yes') {
      setError('expDate', {
        message: "License expiration date must be greater than today's date",
      });
      focusOnDateInput('expDate');

      return;
    }

    setShowServiceAgreement(true);
  });

  useEffect(() => {
    if (imageError) {
      const imgg = document.querySelector(
        'div[id="profileImageInput"]'
      ) as HTMLInputElement;
      imgg.scrollIntoView({ behavior: 'smooth', block: 'end' });

      return;
    }

    if (licenseError) {
      const imgg = document.querySelector(
        'p[id="proofImageInput"]'
      ) as HTMLInputElement;
      imgg.scrollIntoView({ behavior: 'smooth', block: 'end' });

      return;
    }
    const firstErrorKey = Object.keys(errors).find(
      (key) => errors[key as keyof OnboardPatientFormType]
    );

    if (firstErrorKey) {
      (
        document.querySelector(
          `input[name="${firstErrorKey}"]`
        ) as HTMLInputElement | null
      )?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      focusOnDateInput(firstErrorKey);
    }
  }, [errors, imageError, licenseError]);

  function showAgreementModal(type: AgreementType) {
    setAgreementType(type);
    setShowAgreement(true);
  }

  const showPictureMenu = () => {
    if (preview) {
      setIsPictureMenuHidden(false);
    } else {
      hiddenFileInput?.current?.click();
    }
  };

  function handleDeleteProfilePicture() {
    setPreview('');
  }

  const onSubmit = (logoUrl: string) => {
    localStorage.removeItem('login_redirect');
    handleSubmit((data) => {
      const patientData = {
        hasDriverLicense: showDriverLicense === 'Yes',
        firstName: data.firstName,
        lastName: data.lastName,
        password: data.password,
        phone: `+${data.phone}`,
        details: {
          gender: data.gender,
          dob: format(new Date(data.dob), 'yyyy-MM-dd'),
          isMarried: data.married === 'Yes',
          emergencyContactName: data.emergencyContactName,
          emergencyContactNumber: `+${data.emergencyContactNumber}`,
          optInMedicalInfo: offering,
          optInPrescriptions: emailing,
          optInStudyParticipation: studies,
          isLatestContract: true,
          optInSms: smsConsent,
        },
        driversLicenseDetails: {
          license_number: data.licenseNumber,
          expDate: data.expDate
            ? format(new Date(data.expDate), 'yyyy-MM-dd')
            : '',
          state_id: data.stateId,
        },
        ssn: {
          ssnNumber: data.ssn || '',
        },
        token,
      };

      patientOnboardMutation.mutate(patientData, {
        onSuccess: () => {
          navigate({
            pathname: 'patient-login',
            search: `logo-url=${encodeURIComponent(logoUrl)}&name=${
              data.firstName
            }&`,
          });
        },
        onError: () => {
          setShowServiceAgreement(false);
          setShowBHRTAgreement({
            state: false,
            url: '',
          });
        },
      });
    })();
  };
  if (showServiceAgreement) {
    return (
      <ServiceAgreement
        loading={patientOnboardMutation.isLoading}
        onAccept={(logoUrl) => {
          setShowServiceAgreement(false);
          setShowBHRTAgreement({ state: true, url: logoUrl });
        }}
        onDeny={() => {
          setShowServiceAgreement(false);
        }}
      />
    );
  }

  if (showBHRTAgreement.state) {
    return (
      <BHRTAgreement
        loading={patientOnboardMutation.isLoading}
        onAccept={() => {
          onSubmit(showBHRTAgreement.url);
        }}
        onDeny={() => {
          setShowBHRTAgreement({ state: false, url: '' });
        }}
      />
    );
  }

  return (
    <div className=" flex w-screen justify-center bg-background-dark">
      <div className="mb-32 mt-12 flex w-[90%] flex-col items-center scroll-auto bg-background-dark text-black">
        <div className=" relative flex w-full flex-col items-center gap-1 sm:w-[26rem]">
          <div className="sticky top-0 z-40 h-12 w-full  bg-background-dark " />
          <div
            className={classNames(' mb-2    ', {
              '!mb-2': !!preview,
            })}>
            {/* eslint-disable */}
            <div
              id="profileImageInput"
              onClick={() => handleClick('profile')}
              className={classNames(
                ' flex h-20   cursor-pointer items-center justify-center rounded-full  hover:opacity-90',
                {
                  '!rounded-none !bg-transparent': !!preview,
                }
              )}>
              {addProfilePictureMutation.isLoading && (
                <LoadingSpinner className="absolute inset-auto   h-10 w-10 text-primary-light " />
              )}
              {preview ? (
                <div
                  className={classNames('', {
                    '  ': !!preview,
                  })}>
                  <img
                    src={preview}
                    className="mt-2 h-24 w-24 rounded-full  object-fill hover:opacity-80"
                  />
                </div>
              ) : (
                <div
                  className={classNames('relative ', {
                    'opacity-10': addProfilePictureMutation.isLoading,
                  })}>
                  <img
                    src={ProfileIcon}
                    className="mt-2 h-24 w-24 rounded-full border border-white p-4 "
                  />
                </div>
              )}
            </div>

            <input
              id="profile"
              type={InputFieldType.File}
              ref={hiddenFileInput as LegacyRef<HTMLInputElement>}
              onChange={handleChange}
              className="hidden"
              accept="image/* .heic"
            />
            <div className="relative z-30 h-5 ">
              <PencilIcon
                id="pencilIcon"
                onClick={showPictureMenu}
                className={classNames(
                  'relative -top-6 -right-28  cursor-pointer rounded bg-white p-[4px]',
                  {
                    '!pointer-events-none opacity-50':
                      addProfilePictureMutation.isLoading,
                  }
                )}
                height={20}
              />

              <div
                className={classNames(
                  'absolute -top-5  left-24 w-32  flex-col rounded bg-background-main text-base font-semibold  text-background-contrastText',
                  {
                    hidden: isPictureMenuHidden,
                  }
                )}>
                <div
                  onClick={() => handleClick('profile')}
                  className="cursor-pointer rounded-t p-3 pl-3 pt-3 hover:bg-background-light">
                  {!!preview ? 'Change' : 'Upload'}
                </div>
                {!!preview && (
                  <div
                    onClick={handleDeleteProfilePicture}
                    className="cursor-pointer rounded-b p-3 pl-3 pb-3 text-error-main hover:bg-background-light ">
                    Delete
                  </div>
                )}
              </div>
            </div>

            <p
              style={{ minHeight: '1rem' }}
              className={classNames(
                'invisible m-1 text-left text-sm text-background-contrastText ',
                {
                  '!visible !text-error-main': imageError,
                }
              )}>
              Please upload profile picture
            </p>
          </div>
          <div className="flex w-full justify-between gap-4">
            <TextInput
              control={control}
              label="First Name"
              name="firstName"
              placeholder="Enter your first name"
              className="text-xl sm:text-md"
              fullWidth
            />
            <TextInput
              control={control}
              label="Last Name"
              name="lastName"
              placeholder="Enter your last name"
              className="text-xl sm:text-md"
              fullWidth
            />
          </div>{' '}
          <div className="flex w-full justify-between gap-4">
            <DropDown
              className="text-xl sm:text-md"
              control={control}
              name="gender"
              label="Gender"
              fullWidth
              placeholder="Select your gender"
              options={[
                { title: 'Male', value: 'M' },
                { title: 'Female', value: 'F' },
              ]}
              helperText={
                extractValidationErrors(
                  'gender',
                  patientOnboardMutation,
                  errors
                ).msg
              }
              error={
                extractValidationErrors(
                  'gender',
                  patientOnboardMutation,
                  errors
                ).hasError
              }
            />
          </div>{' '}
          <div id="dob" style={{ all: 'inherit' }}>
            <Controller
              control={control}
              name="dob"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <div className="flex w-full flex-col  ">
                  <Typography color="textMuted" variant="h5" className="mb-2">
                    Date of Birth
                  </Typography>
                  <DayPickerWithInput
                    selectedDate={value as unknown as Date}
                    open={dobDatePicker}
                    placeholder="DOB"
                    toggleDayPicker={(s) => {
                      setDobDatePicker(s);
                    }}
                    label=""
                    withInput={true}
                    theme={ThemeName.Dark}
                    helperText={error?.message}
                    error={!!error?.message}
                    onDateSelect={(date) => {
                      onChange({
                        target: { value: date },
                      });

                      setDobDatePicker(false);
                    }}
                  />
                </div>
              )}
            />
          </div>
          <div className="flex w-full justify-between gap-4">
            <TextInput
              control={control}
              label="Mobile Number"
              name="phone"
              type={InputFieldType.Phone}
              placeholder="First Name"
              className="text-xl sm:text-md"
              fullWidth
            />
          </div>
          <Controller
            defaultValue="Yes"
            control={control}
            name="married"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <div className=" flex w-full flex-col  ">
                <Typography color="textMuted" variant="h5" className="mb-2">
                  Are you married/partnered?
                </Typography>
                <div className="flex w-full justify-between gap-4">
                  {' '}
                  <RadioButton
                    fullWidth
                    id="Yes"
                    label="Yes"
                    square
                    onChange={(l) => {
                      onChange({ target: { value: l } });
                    }}
                    checked={isMarried === 'Yes'}
                  />
                  <RadioButton
                    fullWidth
                    id="No"
                    square
                    label="No"
                    onChange={(l) => {
                      onChange({ target: { value: l } });
                    }}
                    checked={isMarried === 'No'}
                  />
                </div>
                <p
                  style={{ minHeight: '1rem' }}
                  className={classNames(
                    'm-1 text-left text-sm text-background-contrastText ',
                    {
                      '!text-error-main': error?.message,
                    }
                  )}>
                  {error?.message}
                </p>
              </div>
            )}
          />{' '}
          <div className="flex w-full justify-between gap-4">
            <TextInput
              control={control}
              label={
                isMarried === 'Yes'
                  ? 'Spouse/Partner Name'
                  : 'Emergency Contact Name'
              }
              name="emergencyContactName"
              placeholder={
                isMarried === 'Yes'
                  ? "What is your spouse/partner's name"
                  : "What is your emergency contact's name"
              }
              className="text-xl sm:text-md"
              fullWidth
            />
          </div>{' '}
          <div className="flex w-full justify-between gap-4">
            <TextInput
              control={control}
              type={InputFieldType.Phone}
              label={
                isMarried === 'Yes'
                  ? 'Spouse/Partner Contact Number'
                  : 'Emergency Contact Number'
              }
              name="emergencyContactNumber"
              placeholder={
                isMarried === 'Yes'
                  ? "What is your spouse/partner's contact number"
                  : "What is your emergency contact's number"
              }
              className="text-xl sm:text-md"
              fullWidth
            />
          </div>{' '}
          <Controller
            control={control}
            name="showDriverLicense"
            defaultValue="Yes"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <div className=" flex w-full flex-col  ">
                <Typography color="textMuted" variant="h5" className="mb-2">
                  Do you have a driver's license??
                </Typography>
                <div className="flex w-full justify-between gap-4">
                  {' '}
                  <RadioButton
                    fullWidth
                    id="Yes"
                    label="Yes"
                    square
                    onChange={(l) => {
                      onChange({ target: { value: 'Yes' } });
                    }}
                    checked={value === 'Yes'}
                  />
                  <RadioButton
                    fullWidth
                    id="No"
                    square
                    label="No"
                    onChange={(l) => {
                      onChange({ target: { value: 'No' } });
                    }}
                    checked={value === 'No'}
                  />
                </div>
                <p
                  style={{ minHeight: '1rem' }}
                  className={classNames(
                    'm-1 text-left text-sm text-background-contrastText ',
                    {
                      '!text-error-main': error?.message,
                    }
                  )}>
                  {error?.message}
                </p>
              </div>
            )}
          />{' '}
          <div className=" flex w-full flex-col justify-between gap-2 ">
            <Typography color="textMuted" variant="h5" className="">
              {showDriverLicense === 'Yes' ? 'License Picture' : 'ID Picture'}
            </Typography>
            {licensePreview ? (
              <div className="relative">
                <img
                  onClick={() => handleClick('license')}
                  src={licensePreview}
                  className="h-48 w-full cursor-pointer rounded object-contain  hover:opacity-70 "
                />
                {(addLicensePictureMutation.isLoading ||
                  addPhotoIdentityMutation.isLoading) && (
                  <LoadingSpinner className="absolute top-1/2  left-1/2 -mt-5 -ml-5 h-10   w-10  text-primary-light " />
                )}
              </div>
            ) : (
              <div
                className={classNames(
                  'relative flex h-36 w-full cursor-pointer  flex-col items-center  rounded border border-dashed border-background-contrastText  bg-background-light px-8  ',
                  {
                    'border-background-light':
                      addLicensePictureMutation.isLoading,
                  }
                )}
                onClick={() => handleClick('license')}>
                {(addLicensePictureMutation.isLoading ||
                  addPhotoIdentityMutation.isLoading) && (
                  <LoadingSpinner className="absolute top-1/2  left-1/2 -mt-5 -ml-5 h-10   w-10  text-primary-light " />
                )}
                <CameraIcon
                  className={classNames('mt-6 h-8 w-8 text-zinc-400', {
                    '!opacity-10':
                      addLicensePictureMutation.isLoading ||
                      addPhotoIdentityMutation.isLoading,
                  })}
                />
                <Typography
                  color="textMuted"
                  align="center"
                  variant="subtitle2"
                  className={classNames('mt-2 w-80', {
                    '!opacity-10':
                      addLicensePictureMutation.isLoading ||
                      addPhotoIdentityMutation.isLoading,
                  })}>
                  {showDriverLicense === 'Yes'
                    ? 'Please upload an image of the front side of your driving license.'
                    : 'Please submit some other form of identification which has your date of birth listed (passport, birth certificate, etc).'}
                </Typography>
              </div>
            )}

            <p
              id="proofImageInput"
              style={{ minHeight: '1rem' }}
              className={classNames(
                'invisible mb-1  text-left text-sm text-background-contrastText ',
                {
                  '!visible !text-error-main': licenseError || !!licensePreview,
                }
              )}>
              {!!licensePreview
                ? 'Is everything clearly visible? If not, the please tap on the picture again and upload a clear image.'
                : 'Please upload picture'}
            </p>
            <input
              id="license"
              type={InputFieldType.File}
              ref={hiddenFileLicenseInput as LegacyRef<HTMLInputElement>}
              onChange={handleChange}
              className="hidden"
              accept="image/*"
            />
          </div>
          {showDriverLicense === 'Yes' && (
            <>
              <div className={'flex w-full justify-between gap-4'}>
                <TextInput
                  control={control}
                  label="Driver's License Number"
                  name="licenseNumber"
                  type={InputFieldType.Text}
                  placeholder="Enter your driver's license number"
                  className="text-xl sm:text-md"
                  fullWidth
                />
              </div>{' '}
              <div id="dob" style={{ all: 'inherit' }}>
                <Controller
                  control={control}
                  name="expDate"
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <div className="flex w-full flex-col  ">
                      <Typography
                        color="textMuted"
                        variant="h5"
                        className="mb-2">
                        Driver's License Expiration Date
                      </Typography>
                      <DayPickerWithInput
                        selectedDate={value as unknown as Date}
                        open={licenseExpDatePicker}
                        placeholder="Expiration Date"
                        toggleDayPicker={(s) => {
                          setLicenseExpDatePicker(s);
                        }}
                        label=""
                        withInput={true}
                        theme={ThemeName.Dark}
                        helperText={error?.message}
                        error={!!error?.message}
                        onDateSelect={(date) => {
                          if (isFuture(date)) {
                            onChange({ target: { value: date } });
                            clearErrors('expDate');
                          } else {
                            onChange({ target: { value: date } });
                            setError('expDate', {
                              message:
                                "License expiration date must be greater than today's date",
                            });
                          }
                          setLicenseExpDatePicker(false);
                        }}
                      />
                    </div>
                  )}
                />
              </div>
              <div className=" flex w-full flex-col justify-between">
                <DropDown
                  searchable
                  fullWidth
                  placeholder="Select the state on driver's license"
                  label="Driver's License State"
                  name="stateId"
                  control={control}
                  theme={ThemeName.Dark}
                  options={(getStatesQuery.data?.data || []).map((item) => ({
                    title: item.name,
                    value: item.id,
                  }))}
                  className="relative"
                  helperText={
                    extractValidationErrors(
                      'stateId',
                      patientOnboardMutation,
                      errors
                    ).msg
                  }
                  error={
                    extractValidationErrors(
                      'stateId',
                      patientOnboardMutation,
                      errors
                    ).hasError
                  }
                />
                <TypoWithLink
                  className="mb-4 pl-2 "
                  text=""
                  linkText="Why do we ask for a Driver's License?"
                  onClick={() => {
                    showAgreementModal('WhyLicense');
                  }}
                />
              </div>
            </>
          )}
          {showDriverLicense === 'No' && (
            <Controller
              control={control}
              name="ssn"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <div className={'flex w-full flex-col'}>
                  <TextInput
                    error={!!error?.message}
                    label="SSN ( Social Security Number )"
                    onChange={(v) => {
                      onChange({ target: { value: formatSsn(v) } });
                    }}
                    name="ssn"
                    value={formatSsn(value)}
                    type={InputFieldType.Text}
                    placeholder="Enter your social security number"
                    className="text-xl sm:text-md"
                    fullWidth
                    disableHelperText
                  />

                  <p
                    style={{ minHeight: '1rem' }}
                    className={classNames(
                      'm-1 text-left text-sm text-background-contrastText ',
                      {
                        '!text-error-main': error?.message,
                      }
                    )}>
                    {error?.message}
                  </p>
                </div>
              )}
            />
          )}
          <div className="flex w-full justify-between gap-4">
            <TextInput
              removeWhiteSpace
              control={control}
              label="Password"
              name="password"
              type={InputFieldType.Password}
              placeholder="Enter Password"
              className="text-xl sm:text-md"
              fullWidth
            />
          </div>
          <div className="flex w-full justify-between gap-4">
            <TextInput
              removeWhiteSpace
              control={control}
              label="Confirm Password"
              name="confirmPassword"
              type={InputFieldType.Password}
              placeholder="Confirm Password"
              className="text-xl sm:text-md"
              fullWidth
            />
          </div>
          <div className="flex flex-col gap-4 self-start">
            <div className="flex gap-1 self-start">
              <CheckBox
                checked={smsConsent}
                onChange={(v) => {
                  setSmsConsent(v);
                }}
                id="smsConsent"
                label=""
              />
              <TypoWithLink
                text="Do you wish to receive notifications or updates over SMS ?"
                linkText=""
                onClick={() => {
                  //
                }}
              />
            </div>
            <div className="flex gap-1 self-start">
              <CheckBox
                checked={emailing}
                id="emailing"
                label=""
                onChange={(v) => {
                  setEmailing(v);
                }}
              />
              <TypoWithLink
                text="Yes, Brite and/or the pharmacy"
                linkText="may email me concerning my prescriptions."
                onClick={() => {
                  showAgreementModal('Emailing');
                }}
              />
            </div>{' '}
            <div className="flex gap-1 self-start">
              <CheckBox
                checked={studies}
                id="no-studies"
                label=""
                onChange={(v) => {
                  setStudies(v);
                }}
              />
              <TypoWithLink
                text=""
                linkText="I agree to share de-identified medical information in medical studies"
                onClick={() => {
                  showAgreementModal('Studies');
                }}
              />
            </div>{' '}
            <div className="flex gap-1 self-start">
              <CheckBox
                checked={offering}
                id="offering"
                label=""
                onChange={(v) => {
                  setOffering(v);
                }}
              />
              <TypoWithLink
                text=""
                linkText="Yes, Please send me occasional emails concerning new medical information and/or medical offerings "
                onClick={() => {
                  showAgreementModal('Offerings');
                }}
              />
            </div>
          </div>
          <Modal
            closeModal={() => {
              //
            }}
            theme={'light'}
            title={''}
            open={showAgreement}
            className={classNames('w-full sm:w-[32rem]')}>
            <Agreements
              onClose={() => {
                setShowAgreement(false);
              }}
              type={agreementType as AgreementType}
            />
          </Modal>
          <Button
            loading={patientOnboardMutation.isLoading}
            onClick={onNext}
            fullWidth
            className="mt-4"
            size="large">
            Next
          </Button>
        </div>
      </div>
    </div>
  );
}
