import React, { useEffect, useState } from 'react';

import { useNavigate, useSearchParams } from 'react-router-dom';

import { Button } from 'components/common/Button/Button';
import { TextInput } from 'components/common/Input/TextInput';
import { Modal } from 'components/common/Modal/Modal';
import { Typography } from 'components/common/Typography/Typography';
import { providerNPISchema } from 'constants/validation-schema';
import { useFormWithErrors } from 'hooks/useFormWithErrors';
import { InputFieldType } from 'types/common';
import { GetDeaLicenses, OnboardingProviderStepsType } from 'types/onboard';

import { AddLicenseDeaModal } from '../AddLicenseDea/AddLicenseDeaModal';
import { useDeaLicenseContext } from '../AddLicenseDea/DeaLicensesContext';
import { ConfirmationModal } from '../ConfirmationModal/ConfirmationModal';
import { DEALicenseListing } from '../DEALicenseListing/DEALicenseListing';
import { useOnboardProviderFormContext } from '../OnboardFormContext';

export function NpiDeaLicenseDetails() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { control, handleSubmit, reset, setError } = useFormWithErrors({
    schema: providerNPISchema,
  });
  const { deaLicenses, handleDEALicenseData } = useDeaLicenseContext();

  const token = searchParams.get('token');
  const [showAddDeaLicenseModal, setShowAddDEALicenseModal] = useState(false);
  const [openConfirmationModal, setShowOpenConfirmationModal] = useState(false);
  const [openDeaLicenseWarningModal, setopenDeaLicenseWarningModal] =
    useState(false);
  const { formState, updateFormState, formErrors } =
    useOnboardProviderFormContext();
  const [reqData, setReqData] =
    useState<OnboardingProviderStepsType['npiDeaLicense']>();
  const handleData = (data: GetDeaLicenses) => {
    handleDEALicenseData(data);
  };
  useEffect(() => {
    if (formErrors && formErrors?.length) {
      // eslint-disable-next-line array-callback-return
      formErrors.map((item, index) => {
        const keys = Object.keys(item);

        if (keys[index] === 'npi') {
          setError('npi', { message: item[keys[index]] });
        }
      });
    }
  }, [formErrors, setError]);

  useEffect(() => {
    if (formState.npiDeaLicense?.npi) {
      reset({ npi: formState.npiDeaLicense?.npi });
    }
  }, [formState, reset]);

  const onSubmit = handleSubmit((data) => {
    if (deaLicenses.length === 0) {
      setopenDeaLicenseWarningModal(true);

      return;
    }

    const licensesDeas = deaLicenses.map((item) => {
      const {
        dea,
        stateId,
        license,
        deaExpiry,
        licenseExpiry,
        supervisingProvider,
      } = item;

      return {
        dea,
        stateId,
        license,
        deaExpiry,
        licenseExpiry,
        supervisingProvider: supervisingProvider?.licenseNumber
          ? supervisingProvider
          : null,
      };
    });

    setReqData({
      npi: data.npi,
      licensesDeas,
    } as unknown as OnboardingProviderStepsType['npiDeaLicense']);
    setShowOpenConfirmationModal(true);
  });

  const handleConfirmation = (res: 'proceed' | 'cancel') => {
    setShowOpenConfirmationModal(false);
    if (res === 'proceed') {
      updateFormState('npiDeaLicense', reqData);

      navigate({
        pathname: '../contact-details',
        search: `token=${token}`,
      });
    } else {
      setShowAddDEALicenseModal(true);
    }
  };

  return (
    <div className=" mt-4 flex flex-col ">
      <div className="grid grid-cols-12">
        <div className="col-span-9 lg:col-span-12">
          {' '}
          <TextInput
            required
            label="NPI Number"
            type={InputFieldType.Text}
            placeholder="NPI Number"
            className="placeholder:text-zinc-400  "
            fullWidth
            name="npi"
            control={control}
          />
          <div className=" mt-5 flex w-full items-center justify-between ">
            <Typography color="textMuted" variant="h5">
              License(s) & DEA(s)
            </Typography>
            <Button
              onClick={() => setShowAddDEALicenseModal(true)}
              variant="outlined">
              Add DEA & License
            </Button>
          </div>
          <div className="mt-6">
            <DEALicenseListing />
          </div>
        </div>
      </div>

      <div className="flex w-full  flex-wrap items-center gap-2 lg:w-1/2 " />
      <div className="mt-8 flex gap-4 ">
        <Button
          variant="outlined"
          onClick={() => {
            navigate({
              pathname: '../specialties',
              search: `token=${token}`,
            });
          }}>
          Go Back
        </Button>{' '}
        <Button onClick={onSubmit}>Next</Button>
      </div>

      <AddLicenseDeaModal
        showAddModal={showAddDeaLicenseModal}
        handleClose={(state: boolean) => {
          setShowAddDEALicenseModal(state);
        }}
        handleDEALicenseData={handleData}
      />

      <Modal
        closeModal={() => {
          setopenDeaLicenseWarningModal(false);
        }}
        open={openDeaLicenseWarningModal}>
        <div>
          <Typography variant="h5">
            No DEA & license record has been added, please add one to proceed.
          </Typography>

          <div className="mt-4 flex justify-center">
            <Button
              color="secondary"
              variant="contained"
              onClick={() => {
                setopenDeaLicenseWarningModal(false);
              }}>
              Close
            </Button>
          </div>
        </div>
      </Modal>

      <ConfirmationModal
        descriptionText={`You have provided the DEA and licenses of ${
          deaLicenses.length === 1
            ? '1 state.'
            : `${deaLicenses.length} different states.`
        }  Are you licensed in any other states?`}
        secondaryButtonText="Yes, I am licensed in more states"
        primaryButtonText="No, I am not licensed in any other states"
        openModal={openConfirmationModal}
        handleConfirmation={handleConfirmation}
      />
    </div>
  );
}
