import React, { useEffect, useState } from 'react';

import { useQuery } from '@tanstack/react-query';
import { format } from 'date-fns';
import { Controller } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Button } from 'components/common/Button/Button';
import { DayPickerWithInput } from 'components/common/DatePicker/DatePicker';
import { DropDown } from 'components/common/DropDown/DropDown';
import { TextInput } from 'components/common/Input/TextInput';
import { Typography } from 'components/common/Typography/Typography';
import { QueryKeys } from 'constants/query-keys';
import { providerBasicInformationSchema } from 'constants/validation-schema';
import { useFormWithErrors } from 'hooks/useFormWithErrors';
import { UserService } from 'services/user';
import { ThemeName } from 'theme/types';
import { InputFieldType } from 'types/common';
import { OnboardingProviderStepsType } from 'types/onboard';
import { isOlderThan18Years } from 'utils/common';

import { BasicInformationConfirmationModal } from './BasicInformationConfirmationModal/BasicInformationConfirmationModal';
import { useOnboardProviderFormContext } from './OnboardFormContext';

type BasicInformationType = {
  providerTitle: string;
  firstName: string;
  lastName: string;
  middleName: string;
  gender: string;
  dob: Date;
};

export function BasicInformation() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const token = searchParams.get('token');
  const [dobDatePicker, setDobDatePicker] = useState(false);
  const { formState, updateFormState } = useOnboardProviderFormContext();
  const [openModal, setOpenModal] = useState(false);
  const [reqData, setReqData] =
    useState<OnboardingProviderStepsType['basicInformation']>();

  const {
    control,
    setValue,
    setError,
    clearErrors,
    handleSubmit,
    reset,
    setFocus,
    getValues,
    watch,
  } = useFormWithErrors<BasicInformationType>({
    schema: providerBasicInformationSchema,
  });

  watch('providerTitle');

  const getProviderSuffixesQuery = useQuery([QueryKeys.ProviderSuffixes], () =>
    UserService.getProviderSuffixes()
  );

  const suffixOptions = getProviderSuffixesQuery.data?.data.suffixes?.map(
    (s) => ({ title: s, value: s })
  );

  const genderOptions = [
    { title: 'Male', value: 'M' },
    { title: 'Female', value: 'F' },
  ];

  useEffect(() => {
    if (formState.basicInformation) {
      const { dob, firstName, gender, lastName, middleName, providerTitle } =
        formState.basicInformation;
      reset({
        dob: new Date(dob),
        firstName,
        lastName,
        gender,
        middleName,
        providerTitle,
      });
    }
  }, [formState, reset, setValue]);

  const handleDobValidation = (date: Date) => {
    if (isOlderThan18Years(new Date(date) as Date)) {
      return true;
    }
    setError('dob', {
      message: 'Age must be between 18 and 100 years',
    });
    setValue('dob', date);

    return false;
  };

  const onSubmit = handleSubmit((data) => {
    if (!handleDobValidation(data.dob)) {
      return;
    }
    const basicInformation = {
      ...data,
      dob: format(new Date(data.dob), 'yyyy-MM-dd'),
    };

    setReqData(basicInformation);
    setOpenModal(true);
  });

  const handleConfirmation = (res: 'proceed' | 'cancel') => {
    setOpenModal(false);
    if (res === 'proceed') {
      updateFormState('basicInformation', reqData);

      navigate({
        pathname: '../specialties',
        search: `token=${token}`,
      });
    } else {
      setFocus('firstName');
    }
  };

  return (
    <div className="mt-4">
      {' '}
      <div className="grid grid-cols-12 gap-x-4">
        <div className="col-span-12 flex flex-col lg:col-span-9">
          <div className="flex w-full flex-col">
            <Typography color="textMuted" variant="h5" className="mb-2">
              Your Credentials<span className="mx-1 text-error-dark">*</span>
            </Typography>
            <DropDown
              placeholder="Select title"
              control={control}
              name="providerTitle"
              className="w-1/3 pr-2.5"
              defaultValue={suffixOptions?.find(
                (suffix) => suffix.title === getValues('providerTitle')
              )}
              fullWidth
              options={suffixOptions}
              onChange={(s) => {
                setValue('providerTitle', s.title);
              }}
            />
          </div>
          <div className="flex">
            <TextInput
              required
              fullWidth
              label="First Name"
              name="firstName"
              type={InputFieldType.Text}
              control={control}
              placeholder="First Name"
              className=""
            />
            <span className="mx-2" />
            <TextInput
              fullWidth
              label="Middle Name"
              name="middleName"
              type={InputFieldType.Text}
              control={control}
              placeholder="Middle Name"
              className=""
            />

            <span className="mx-2" />
            <TextInput
              required
              label="Last Name"
              name="lastName"
              type={InputFieldType.Text}
              control={control}
              placeholder="Last Name"
              fullWidth
            />
          </div>
          <Typography variant="h5" className="mb-2">
            Please ensure that your name matches the one registered in the
            National Provider Identifier (NPI) database.
          </Typography>
        </div>{' '}
        <div className=" col-span-12 mt-0 flex flex-col lg:col-span-9">
          <div className=" mt-3 flex justify-start">
            <div className="w-1/3 pr-2.5">
              <Typography color="textMuted" variant="h5" className="mb-2">
                Gender <span className="mx-1 text-error-dark">*</span>
              </Typography>
              <DropDown
                className="w-full "
                control={control}
                name="gender"
                fullWidth
                placeholder="Select gender"
                defaultValue={genderOptions?.find(
                  (suffix) => suffix.value === getValues('gender')
                )}
                options={genderOptions}
              />
            </div>
            <span className="mx-1" />
            <Controller
              control={control}
              name="dob"
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <div className="flex w-1/3 flex-col pr-3.5  ">
                  <Typography color="textMuted" variant="h5" className="mb-2">
                    Date of Birth{' '}
                    <span className="mx-1 text-error-dark">*</span>
                  </Typography>
                  <DayPickerWithInput
                    selectedDate={value as unknown as Date}
                    open={dobDatePicker}
                    placeholder=" Select DOB"
                    toggleDayPicker={(s) => {
                      setDobDatePicker(s);
                    }}
                    label=""
                    withInput
                    theme={ThemeName.Dark}
                    helperText={error?.message}
                    error={!!error?.message}
                    onDateSelect={(date) => {
                      const res = handleDobValidation(date);
                      if (res) {
                        onChange({
                          target: { value: date },
                        });
                        clearErrors('dob');
                      }
                      setDobDatePicker(false);
                    }}
                  />
                </div>
              )}
            />
          </div>
        </div>
      </div>
      <div className="mt-8 flex gap-4 ">
        <Button
          variant="outlined"
          onClick={() => {
            navigate({
              pathname: '../profile-picture',
              search: `token=${token}`,
            });
          }}>
          Go Back
        </Button>{' '}
        <Button onClick={onSubmit}>Next</Button>
      </div>
      <BasicInformationConfirmationModal
        openModal={openModal}
        name={`${getValues('firstName')} ${
          getValues('middleName') || ''
        } ${getValues('lastName')}`}
        handleConfirmation={handleConfirmation}
      />
    </div>
  );
}
