import React, { useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import classNames from 'classnames';
import { format, isFuture } from 'date-fns';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';

import { Button } from 'components/common/Button/Button';
import { DayPickerWithInput } from 'components/common/DatePicker/DatePicker';
import { DropDown } from 'components/common/DropDown/DropDown';
import { TextInput } from 'components/common/Input/TextInput';
import { Modal } from 'components/common/Modal/Modal';
import { RadioButton } from 'components/common/RadioButton/RadioButton';
import { Typography } from 'components/common/Typography/Typography';
import { providerTitlesNotRequiringSupervisorDoctor } from 'constants/provider-constants';
import { QueryKeys } from 'constants/query-keys';
// import {
//   addDeaAndLicenseSchema,
//   supervisingDoctorSchema,
// } from 'constants/validation-schema';
import {
  addDeaAndLicenseSchema,
  supervisingDoctorSchema,
  deaSchema,
} from 'constants/validation-schema';
import { useMutationWithToast } from 'hooks/useMutationWithToast';
import { extractValidationErrors } from 'lib/general/extractors';
import { UserService } from 'services/user';
import { ThemeName } from 'theme/types';
import {
  DeaLicense,
  GetDeaLicenses,
  SupervisingProvider,
} from 'types/onboard/index';

import { useOnboardProviderFormContext } from '../OnboardFormContext';
import { useDeaLicenseContext } from './DeaLicensesContext';
import { SupervisingProviderConfirmationModal } from './SupervisingProviderConfirmationModal/SupervisingProviderConfirmationModal';
import { SupervisingProviderDetails } from './SupervisingProviderDetails/SupervisingProviderDetails';

type UpdateProps =
  | {
      actionType?: 'update' | 'clone';
      deaLicenseData: GetDeaLicenses | undefined;
      updationStateId: string | undefined;
    }
  | {
      actionType?: undefined;
      deaLicenseData?: never;
      updationStateId?: never;
    };

type DeaLicenseReqType = DeaLicense & {
  supervisingProvider: SupervisingProvider | null;
};
type AddLicenseDEAProps = {
  showAddModal: boolean;
  handleClose: (state: boolean) => void;
  // setShowAddModal: Dispatch<SetStateAction<boolean>>;
  handleDEALicenseData?: (data: GetDeaLicenses) => void;
} & UpdateProps;
type AddFormType = {
  dea: string;
  license: string;
  stateId: string;
  licenseExpiry: Date;
  deaExpiry: Date;
  supervisingDea: string;
  firstName: string;
  middleName: string;
  lastName: string;
  npi: string;
  licenseNumber: string;
  suffix: string;
  licenseState: string;
};
export function AddLicenseDeaModal({
  showAddModal,
  handleClose,
  actionType,
  deaLicenseData,
  handleDEALicenseData,
  updationStateId,
}: AddLicenseDEAProps) {
  const [repeatError, setRepeatError] = useState(false);
  const queryClient = useQueryClient();
  const [showDEAExpiryDateSelector, setShowDEAExpiryDateSelector] =
    useState(false);
  const [hasSupervisingProvider, setHasSupervisingProvider] = useState(false);
  const [selectedDEAExpiryDate, setSelectedDEAExpiryDate] = useState<Date>();
  const [showLicensexpiryDateSelector, setShowLicenseExpiryDateSelector] =
    useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [selectedLicenseExpiryDate, setSelectedLicenseExpiryDate] =
    useState<Date>();
  const [reqBody, setReqBody] = useState<DeaLicenseReqType>();
  const [hasDEANumber, setHasDEANumber] = useState(true);

  const { deaLicenses } = useDeaLicenseContext();
  const { formState } = useOnboardProviderFormContext();

  const getStatesQuery = useQuery(
    [QueryKeys.States],
    () => UserService.getStates(),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  let validationSchema = addDeaAndLicenseSchema();

  // Conditionally concatenate the DEA schema
  if (hasDEANumber) {
    validationSchema = validationSchema.concat(deaSchema());
  }

  // Conditionally concatenate the Supervising Doctor schema
  if (hasSupervisingProvider) {
    validationSchema = validationSchema.concat(supervisingDoctorSchema);
  }
  const {
    handleSubmit,
    control,
    reset,
    clearErrors,
    formState: { errors },
    getValues,
    setValue,
    watch,
  } = useForm<AddFormType>({ resolver: yupResolver(validationSchema) });

  const findDuplicteRecordWithState = (stateId: string): boolean =>
    !!deaLicenses.find((item) => item.stateId === stateId);

  const isSuperVisingProviderRequired =
    !providerTitlesNotRequiringSupervisorDoctor.includes(
      formState.basicInformation?.providerTitle || ''
    );

  const onSubmit = handleSubmit((data) => {
    if (findDuplicteRecordWithState(data.stateId) && !actionType) {
      toast.error('A record for this state has already been added.');

      return;
    }

    const requestBody = {
      stateId: data.stateId,
      dea: hasDEANumber ? data.dea : null,
      deaExpiry: hasDEANumber
        ? format(new Date(selectedDEAExpiryDate ?? ''), 'yyyy-MM-dd')
        : null,
      licenseExpiry: format(
        new Date(selectedLicenseExpiryDate ?? ''),
        'yyyy-MM-dd'
      ),
      license: data.license,
      supervisingProvider: hasSupervisingProvider
        ? {
            dea: data.supervisingDea,
            licenseNumber: data.licenseNumber,
            npi: data.npi.toString(),
            licensedPrescriberFirstName: data.firstName,
            licensedPrescriberLastName: data.lastName,
            licensedPrescriberMiddleName: data.middleName,
            licensedPrescriberSuffix: data.suffix,
            licenseState: data.stateId,
          }
        : null,
    };
    if (hasSupervisingProvider || !isSuperVisingProviderRequired) {
      handleConfirmation(requestBody);
    } else {
      setReqBody(requestBody);
      setShowConfirmationModal(true);
    }
  });

  function getStateNameById(stateId: string) {
    const state = getStatesQuery.data?.data.find((item) => item.id === stateId);

    return state ? state.name : '';
  }
  const providerOnboardMutation = useMutationWithToast(
    UserService.onBoardProvider,
    {}
  );

  useEffect(() => {
    if (actionType && deaLicenseData && updationStateId) {
      if (
        deaLicenseData.supervisingProvider &&
        deaLicenseData.supervisingProvider.licenseNumber
      ) {
        setHasSupervisingProvider(true);
      }
      if (deaLicenseData.deaExpiry) {
        setSelectedDEAExpiryDate(new Date(deaLicenseData.deaExpiry));
      }
      if (deaLicenseData.licenseExpiry) {
        setSelectedLicenseExpiryDate(new Date(deaLicenseData.licenseExpiry));
      }

      if (deaLicenseData.dea && deaLicenseData.deaExpiry) {
        setHasDEANumber(true);
      }

      reset({
        stateId: actionType === 'clone' ? '' : deaLicenseData.stateId,
        dea: deaLicenseData.dea || '',
        license: deaLicenseData.license,
        deaExpiry: deaLicenseData.deaExpiry
          ? new Date(deaLicenseData.deaExpiry || '')
          : undefined,
        licenseExpiry: new Date(deaLicenseData.licenseExpiry),
        firstName:
          deaLicenseData?.supervisingProvider?.licensedPrescriberFirstName ||
          '',
        lastName:
          deaLicenseData?.supervisingProvider?.licensedPrescriberLastName || '',
        middleName:
          deaLicenseData?.supervisingProvider?.licensedPrescriberMiddleName ||
          '',
        licenseState: actionType === 'clone' ? '' : deaLicenseData.stateId,
        licenseNumber: deaLicenseData?.supervisingProvider?.licenseNumber,
        supervisingDea: deaLicenseData?.supervisingProvider?.dea,
        npi: deaLicenseData?.supervisingProvider?.npi,
        suffix: deaLicenseData?.supervisingProvider?.licensedPrescriberSuffix,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionType, deaLicenseData, updationStateId]);

  const onClose = () => {
    queryClient.refetchQueries([QueryKeys.UserProfile]);
    setRepeatError(false);
    setHasSupervisingProvider(false);

    setSelectedDEAExpiryDate(undefined);
    setSelectedLicenseExpiryDate(undefined);
    setHasDEANumber(true);

    reset();
    clearErrors();
    handleClose(false);
  };

  const getValidationErrors = (fieldName: string) =>
    extractValidationErrors(fieldName, providerOnboardMutation, errors);

  const states = (getStatesQuery.data?.data || []).map((item) => ({
    title: item.name,
    value: item.id,
  }));
  const stateId = watch('stateId');

  useEffect(() => {
    if (stateId) {
      setValue('licenseState', stateId, {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
  }, [stateId, setValue]);

  const handleConfirmation = (req: DeaLicenseReqType) => {
    setShowConfirmationModal(false);
    if (req) {
      const tempData: GetDeaLicenses = {
        ...req,
        state: getStateNameById(req.stateId) || '',
      };

      handleDEALicenseData?.(tempData);
      onClose();
    }
  };

  return (
    <Modal
      className="w-[55%] overflow-visible"
      open={showAddModal}
      closeModal={() => {
        //
      }}>
      <div className="flex flex-col">
        <div className="w-100 mb-3 flex h-10 items-center rounded bg-primary-main p-3">
          <Typography color="white" variant="body1">
            Please provide your DEA & License Details
          </Typography>
        </div>
        <div className="flex items-center justify-between gap-4">
          <DropDown
            theme={ThemeName.Light}
            searchable
            label="Select State"
            fullWidth
            name="stateId"
            disabled={actionType === 'update'}
            defaultValue={states.find((s) => s.value === getValues('stateId'))}
            control={control}
            placeholder="Select State"
            options={states || []}
          />
        </div>

        <div className="flex gap-4">
          <TextInput
            name="license"
            control={control}
            required
            fullWidth
            label="License"
            placeholder="Enter License number"
          />
          <div className="w-full grow">
            <Controller
              control={control}
              name="licenseExpiry"
              render={({ field: { onChange }, fieldState: { error } }) => (
                <DayPickerWithInput
                  required
                  // position="ltr"
                  toggleDayPicker={(t) => setShowLicenseExpiryDateSelector(t)}
                  placeholder="Select Date"
                  label="License Expiration"
                  withInput
                  helperText={error?.message}
                  error={!!error?.message}
                  selectedDate={selectedLicenseExpiryDate}
                  open={showLicensexpiryDateSelector}
                  theme={ThemeName.Dark}
                  onDateSelect={(d) => {
                    if (!isFuture(d)) {
                      onChange({
                        target: { value: d },
                      });
                    } else {
                      onChange({
                        target: { value: d },
                      });
                      clearErrors('licenseExpiry');
                    }

                    setSelectedLicenseExpiryDate(d);
                    setShowLicenseExpiryDateSelector(false);
                  }}
                />
              )}
            />
          </div>
        </div>

        {stateId ? (
          <div className="col-span-9  mt-4 flex flex-col justify-center text-start ">
            <Typography variant="h5">
              Do you hold a DEA license in the state of{' '}
              {states.find((item) => item.value === stateId)?.title}?
            </Typography>

            <div className="my-3 flex w-8 items-center">
              <RadioButton
                label="Yes"
                checked={hasDEANumber}
                id="hasDEA"
                onChange={() => {
                  setHasDEANumber(true);
                }}
              />
              <span className="mx-4" />
              <RadioButton
                label="No"
                checked={!hasDEANumber}
                id="hasDEA"
                onChange={() => setHasDEANumber(false)}
              />
            </div>
          </div>
        ) : null}

        {stateId && hasDEANumber ? (
          <div className="flex gap-4">
            <TextInput
              name="dea"
              control={control}
              required
              fullWidth
              label="DEA"
              placeholder="Enter DEA number"
            />
            <div className="w-full grow">
              <Controller
                control={control}
                name="deaExpiry"
                render={({ field: { onChange }, fieldState: { error } }) => (
                  <DayPickerWithInput
                    // position="ltr"
                    toggleDayPicker={(t) => setShowDEAExpiryDateSelector(t)}
                    placeholder="Select Date"
                    label="DEA Expiration"
                    withInput
                    helperText={error?.message}
                    error={!!error?.message}
                    selectedDate={selectedDEAExpiryDate}
                    open={showDEAExpiryDateSelector}
                    theme={ThemeName.Dark}
                    onDateSelect={(d) => {
                      if (!isFuture(d)) {
                        onChange({
                          target: { value: d },
                        });
                      } else {
                        onChange({
                          target: { value: d },
                        });
                        clearErrors('deaExpiry');
                      }
                      setSelectedDEAExpiryDate(d);
                      setShowDEAExpiryDateSelector(false);
                    }}
                  />
                )}
              />
            </div>
          </div>
        ) : null}

        {stateId && isSuperVisingProviderRequired ? (
          <div className="col-span-9  mt-4 flex flex-col justify-center text-start ">
            <Typography variant="h5">
              Do you need a supervising provider for the state of{' '}
              {states.find((item) => item.value === stateId)?.title}?
            </Typography>
            <div className="my-3 flex w-8 items-center">
              <RadioButton
                label="Yes"
                checked={hasSupervisingProvider}
                id="supervisingProvider"
                onChange={() => {
                  setHasSupervisingProvider(true);
                }}
              />
              <span className="mx-4" />
              <RadioButton
                label="No"
                checked={!hasSupervisingProvider}
                id="supervisingProvider"
                onChange={() => setHasSupervisingProvider(false)}
              />
            </div>
          </div>
        ) : null}

        {hasSupervisingProvider ? (
          <SupervisingProviderDetails
            control={control}
            getValidationErrors={getValidationErrors}
            getValues={getValues}
            states={states}
          />
        ) : null}
        <Typography
          color="error"
          className={classNames('invisible mt-1', {
            '!visible': repeatError,
          })}
          variant="subtitle3">
          * This details or state already exists
        </Typography>
        <div className="mt-4 flex justify-end">
          <Button color="secondary" variant="contained" onClick={onClose}>
            Close
          </Button>{' '}
          <span className="mx-2" />
          <Button onClick={onSubmit}>
            {actionType === 'update' ? 'Update' : 'Add'}
          </Button>
        </div>

        <div>
          <SupervisingProviderConfirmationModal
            showConfirmationMoal={showConfirmationModal}
            state={states.find((item) => item.value === stateId)?.title || ''}
            onCancel={() => {
              setShowConfirmationModal(false);
            }}
            onConfirm={() => handleConfirmation(reqBody as DeaLicenseReqType)}
          />
        </div>
      </div>
    </Modal>
  );
}
