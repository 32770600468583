import { Modal } from 'components/common/Modal/Modal';
import { Typography } from 'components/common/Typography/Typography';

type ModalProps = {
  openModal: boolean;
  name: string;
  handleConfirmation: (response: 'proceed' | 'cancel') => void;
};

export function BasicInformationConfirmationModal({
  openModal,
  handleConfirmation,
  name,
}: ModalProps) {
  return (
    <Modal
      open={openModal}
      closeModal={() => {
        //
      }}>
      <div>
        <Typography color="gray" variant="h5">
          Please ensure that your name matches the one registered in the
          National Provider Identifier (NPI) database.
        </Typography>
      </div>

      <div className="mt-6 w-max rounded-lg bg-background-dark p-3">
        <Typography variant="subtitle2">{name}</Typography>
      </div>

      <div
        role="button"
        className="mt-6  w-max rounded-lg border bg-error-main p-3"
        tabIndex={0}
        onClick={() => handleConfirmation('cancel')}
        onKeyDown={() => {
          //
        }}>
        <Typography variant="subtitle2" color="white" className="underline">
          {' '}
          I would like to correct my name
        </Typography>
      </div>

      <div
        role="button"
        tabIndex={0}
        className="text-white-main my-4 w-max rounded-lg  border border-primary-main bg-primary-main p-3"
        onClick={() => handleConfirmation('proceed')}
        onKeyDown={() => {
          //
        }}>
        <Typography variant="subtitle2" color="white" className="underline">
          {' '}
          Yes, my name matches the one registered in NPI database.
        </Typography>
      </div>
    </Modal>
  );
}
